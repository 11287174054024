import { createRouter, createWebHistory } from 'vue-router'

const Layouts = () => import('@/layouts/index.vue')
const Full = () => import('@/layouts/full.vue')

const routes = [{
  path: '/',
  component: Layouts,
  redirect: '/home',
  children: [{
    path: '',
    name: 'home',
    component: () => import('@/views/home/index.vue'),
    meta: {
      title: '油满满', requireAuth: true, keepAlive: true,
    }
  }]
}]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export { routes }
export default router
