/**
 * Vue 实例化
 */

import { createApp } from 'vue'
import router from '../router'
import store from '@/store'
import App from '../App.vue'

async function bootstrap() {

  const app = createApp(App)
  app.use(router)
  app.use(store)
  app.mount('#main')

}

void bootstrap()
