import { createPinia, storeToRefs } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import useGlobalConfigStore from './modules/globalConfigStore'

const store = createPinia()

// Pinia 插件持久状态
store.use(piniaPluginPersistedstate)

export {
  useGlobalConfigStore,

  storeToRefs
}

export default store
